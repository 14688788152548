import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = ({ type, data }) => {
  const location = useLocation();

  const getCategoryDisplayName = (category) => {
    switch (category) {
      case 'robot-kits':
        return 'Robot Kits';
      case 'robot-parts':
        return 'Robot Parts';
      case 'accessories':
        return 'Accessories';
      case 'all-items':
        return 'All Items';
      default:
        return category; // If not found, return the original category name
    }
  };

  if (type === "item") {
    return (
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/category/${data?.category}` }}>
          {getCategoryDisplayName(data?.category)}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data?.name}</Breadcrumb.Item>
      </Breadcrumb>
    );
  } else if (type === "category") {
    const urlEndsWithAllItems = location.pathname.endsWith("all-items");
    const urlIsRoot = location.pathname === "/";
    return (
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>
          {urlEndsWithAllItems ? "All Items" : (urlIsRoot ? "Popular" : (data?.items?.length > 0 ? getCategoryDisplayName(data.items[0].category) : 'Category Name'))}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  } else {
    return null; // Render nothing if type is neither "item" nor "category"
  }
};

export default Breadcrumbs;
