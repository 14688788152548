import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../actions/actions";
import { Row, Col, Button, Container } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import ItemImageCarousel from "../components/ItemImageCarousel";
import QuantityControl from "../components/QuantityControl";
import Breadcrumbs from "../components/Breadcrumbs";
import AddToCartAlert from "../components/AddToCartAlert"; // Import the AddToCartAlert component

import "./Item.css";

function Item({ addToCart }) {
    const { itemID } = useParams();
    const [itemData, setItemData] = useState(undefined);
    const [quantity, setQuantity] = useState(1);
    const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

    function getCategoryName(category) {
        switch (category) {
          case 'robot-kits':
            return 'Robot Kits';
          case 'robot-parts':
            return 'Robot Parts';
          case 'accessories':
            return 'Accessories';
          default:
            return category;
        }
      }
      

    useEffect(() => {
        const fetchItemData = async () => {
            try {
                const response = await axios.get(`https://svkrobotics.com/api/items/${itemID}`);
                setItemData(response.data.item);
            } catch (error) {
                console.error('Error fetching item data:', error);
            }
        };

        fetchItemData();
    }, [itemID]);

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    const handleAddToCart = () => {
        const uuid = uuidv4();
        addToCart(itemData, quantity, uuid);
        setShowAlert(true); // Show alert when item is added to cart
    };

    if (itemData === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div className="item-page">
            <Container>
                <Col>
                    {/* Render the alert component */}
                    <AddToCartAlert showAlert={showAlert} setShowAlert={setShowAlert} />
                </Col>
            </Container>
            <Row className="item-sale-cont">
                <Breadcrumbs type="item" data={itemData} />
                <Col>
                    <Container>
                        <ItemImageCarousel data={itemData} />
                    </Container>
                </Col>

                <Col>
                    <Container>
                        <Col>
                            <h1>{itemData?.name}</h1>
                            <hr />
                            <p>{itemData?.description}</p>
                            <p className="item-categories">Category: {getCategoryName(itemData?.category)}</p>
                            <h3 className="item-price">Price: Inquire for price details</h3>
                            <Row className="buy-row" md={2}>
                                <Col>
                                    <QuantityControl onQuantityChange={handleQuantityChange} />
                                </Col>
                                <Col className="buy-button-col">
                                    <Button size="lg" className="buy-button" onClick={handleAddToCart}>
                                        Add to cart
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Col>
            </Row>


        </div>
    );
}

export default connect(null, { addToCart })(Item);
