import React, { useState, useEffect } from "react";
import { ButtonGroup, Button, InputGroup, FormControl, Container } from 'react-bootstrap';

import './QuantityControl.css';

function QuantityControl({ onQuantityChange, defaultQuantity }) {
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (defaultQuantity) {
      setQuantity(defaultQuantity);
      onQuantityChange(defaultQuantity);
    }
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  const handleDecrease = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  const handleIncrease = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onQuantityChange(newQuantity);
  };

  const handleInputChange = (event) => {
    const inputQuantity = parseInt(event.target.value, 10);

    if (!isNaN(inputQuantity) && inputQuantity >= 1) {
      setQuantity(inputQuantity);
      onQuantityChange(inputQuantity);
    } else if (event.target.value === '') {
      // Allow the input to be cleared
      setQuantity('');
      onQuantityChange(null); // You can pass null or any other value when the input is cleared
    }
  };

  const handleInputBlur = () => {
    // If the input is left empty, default it to 1
    if (quantity === '') {
      setQuantity(1);
      onQuantityChange(1);
    }
  };

  return (
    <Container className="quantity-cont">
      <p className="quantity-text">Quantity:</p>
      <ButtonGroup>
        <Button size="md" variant="secondary" onClick={handleDecrease}>-</Button>
        <InputGroup>
          <FormControl
            size="sm"
            type="text"
            value={quantity}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            style={{ width: "60px", textAlign: "center" }}
          />
        </InputGroup>
        <Button size="md" variant="secondary" onClick={handleIncrease}>+</Button>
      </ButtonGroup>
    </Container>
  );
}

export default QuantityControl;
