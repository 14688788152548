import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Newsletter from './Newsletter';
import './Footer.css';


function Footer() {
  return (
    <div className='footer-div'>
      <Container>
        <Row className='md-3'>
          <Col md={3} className='footer-logo-cont'>
            <img src='/logos/logo SVK.png' className='footer-logo' alt='Logo-footer' />
          </Col>
          <Col md={3} className='socials-cont'>
            <p className='follow-us'>Follow us on</p>
            <Row className='social-icon-row'>
              <Col sm={2}>
                <a href='https://www.facebook.com/profile.php?id=61552752842212' target='_blank' rel='noopener noreferrer'>
                  <img className='social-icon' src='/icons/facebook.svg' alt='social-1' />
                </a>
              </Col>
              <Col sm={2}>
                <a href='https://www.instagram.com/svk.robotics/' target='_blank' rel='noopener noreferrer'>
                  <img className='social-icon' src='/icons/instagram.svg' alt='social-2' />
                </a>
              </Col>
              <Col sm={2}>
                <a href='https://github.com/SVKROBOTICS' target='_blank' rel='noopener noreferrer'>
                  <img className='social-icon' src='/icons/github.svg' alt='social-4' />
                </a>
              </Col>

            </Row>
          </Col>

          <Col md={3} className='email-cont'>
            <p>Contact us at:</p>
            <Row className='email-contact'>
              <Col>
                <h6>info@svkrobotics.com</h6>
              </Col>
            </Row>
          </Col>

          <Col md={3}>
          <Container className='newsletter-cont'><Newsletter /></Container>
          </Col>

        </Row>

        {/* Trademark and powered by, in some way */}
      </Container>
    </div>
  );
}

export default Footer;
