import React, { useState } from "react";
import { Button, Card, Container, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'; // Import connect
import { addToCart } from '../actions/actions'; // Import addToCart action
import { v4 as uuidv4 } from 'uuid'; // Import UUID library
import AddToCartAlert from "./AddToCartAlert"; // Import the AddToCartAlert component


import './ItemCard.css';

function ItemCard({ item, addToCart }) {
  const [isHovered, setIsHovered] = useState(false);
  const [showAlert, setShowAlert] = useState(false);


  const handleAddToCart = (e) => {
    e.preventDefault(); // Prevent default button behavior

    // Generate UUID
    const uuid = uuidv4();

    // Dispatch addToCart action with item, quantity, and UUID
    addToCart(item, 1, uuid);

    setShowAlert(true); // Show alert when item is added to cart

  };

  // Determine the image source based on whether the item has one or two images
  const imageSource = isHovered && item.images[1] ? item.images[1] : item.images[0];

  return (
    <>
    <Container>
      <Col>
          {/* Render the alert component */}
          <AddToCartAlert showAlert={showAlert} setShowAlert={setShowAlert} />
      </Col>
    </Container>
    <Link to={`/item/${item.id}`} style={{ textDecoration: 'none' }}>
      <Card className={isHovered ? "hovered" : ""}>
        <div className="image-container">
          <img
            src={imageSource}
            alt={item.name}
            className="card-img"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </div>
        <Card.Body>
          <Card.Title className="card-name">{item.name}</Card.Title>
          <Card.Text className="card-desc">{item.description}</Card.Text>
          {/* <Card.Title className="card-price">${item.price}</Card.Title> */}

          <Button className="cart-button" size="lg" onClick={handleAddToCart}>
            Add to cart
          </Button>
        </Card.Body>
      </Card>


    </Link>
    </>
  );
}

// Connect the component to the Redux store
export default connect(null, { addToCart })(ItemCard);
