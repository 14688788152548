import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Carousel } from 'react-bootstrap';
import axios from 'axios';
import { CustomPrevArrow, CustomNextArrow } from "./CustomArrows";

import './MainCarousel.css';

function MainCarousel() {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchHighlightedItems = async () => {
      try {
        const response = await axios.get("https://svkrobotics.com/api/items/get/highlighted");
        setItems(response.data.items);
      } catch (error) {
        console.error("Error fetching highlighted items:", error);
      }
    };

    fetchHighlightedItems();
  }, []);

  const handleImageClick = (itemId) => {
    navigate(`/item/${itemId}`);
  };

  return (
    <Container className="image-cont">
      <Carousel
        prevIcon={<CustomPrevArrow />}
        nextIcon={<CustomNextArrow />}
      >
        {items?.map(item => (
          <Carousel.Item key={item.id}>
            <img
              className="d-block w-100"
              src={item.images}
              alt={item.name}
              onClick={() => handleImageClick(item.id)}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
}

export default MainCarousel;
