import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ItemCard from './ItemCard';
import Breadcrumbs from './Breadcrumbs';
import axios from 'axios';

import './ItemGrid.css';

const calculateCols = (screenWidth) => {
  if (screenWidth >= 1200) {
    return 3; // Large screens (lg)
  } else if (screenWidth >= 992) {
    return 3; // Medium screens (md)
  } else if (screenWidth >= 768) {
    return 2; // Small screens (sm)
  } else {
    return 1; // Extra small screens (xs)
  }
};

function ItemGrid({ category }) {
  const screenWidth = window.innerWidth;
  const cols = calculateCols(screenWidth);

  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data using Axios when the category changes
    const fetchData = async () => {
      let url = `https://svkrobotics.com/api/items/category/${category}`;

      // Check if category is "highlight" or "all-items", change the URL accordingly
      if (category === "highlight") {
        url = 'https://svkrobotics.com/api/items/get/highlighted';
      } else if (category === "all-items") {
        url = 'https://svkrobotics.com/api/items/get/all';
      }

      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (category) {
      fetchData();
    }
  }, [category]);

  return (
    <>
      <Container className='grid-cont'>
        <Breadcrumbs type="category" data={data} />
        {data && (
          <Row>
            {data?.items?.map((item) => (
              <Col className='item-grid-col' key={item.id} xs={12} sm={12 / cols} md={12 / cols} lg={12 / cols}>
                <ItemCard item={item} />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </>
  );
}

export default ItemGrid;
