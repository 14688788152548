import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ShopGrid from './pages/ShopGrid';
import Landing from './pages/Landing';
import ShoppingCart from './pages/ShoppingCart';
import Item from './pages/Item';
import Checkout from './pages/Checkout';
import Confirmation from './pages/Confirmation';

// bootstrap css file
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Header />

      <Routes>
        <Route exact path='/' element={<Landing />} />
        <Route exact path='*' element={<Landing />} />
        <Route exact path='/category/:categoryUrl' element={<ShopGrid />} />
        <Route exact path='/item/:itemID' element={<Item />} />
        <Route exact path='/cart' element={<ShoppingCart />} />
        <Route path='/checkout/:orderUUID' element={<Checkout />} />
        <Route path='/confirmation' element={<Confirmation />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
