import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';

function AddToCartAlert({ showAlert, setShowAlert }) {
  const [show, setShow] = useState(showAlert);

  useEffect(() => {
    setShow(showAlert);
    const timer = setTimeout(() => {
      setShow(false);
      setShowAlert(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [showAlert, setShowAlert]);

  return (
    <>
      <Alert show={show} variant="success">
        <Alert.Heading>Item added to cart successfully</Alert.Heading>
      </Alert>
    </>
  );
}

export default AddToCartAlert;
