// Newsletter.js

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import './Newsletter.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle your newsletter subscription logic here
    console.log(`Subscribed with email: ${email}`);
    // You can send the email to your backend for further processing
  };

  return (        
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
        <Form.Label>Subscribe to our Newsletter</Form.Label>

        <ButtonGroup>
          <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
          />
          
          <Button disabled className='newsletter-button' variant="dark" type="submit" size='sm'>
              Subscribe
          </Button>
        </ButtonGroup>

        </Form.Group>
      </Form>
  );
};

export default Newsletter;
