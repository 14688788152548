import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Figure from 'react-bootstrap/Figure';
import countries from '../data/countries.json';
import axios from 'axios';

import "./Checkout.css";

function Checkout() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    zip: '',
    country: '',
    deliveryOption: '',
  });
  const [createdOrderId, setCreatedOrderId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeliveryOptionChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      deliveryOption: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if all required fields are filled
    const requiredFields = ['firstName', 'lastName', 'email', 'addressLine1', 'city', 'province', 'zip', 'country', 'deliveryOption'];
    const isAllFieldsFilled = requiredFields.every(field => formData[field]);
  
    if (!isAllFieldsFilled) {
      // Display an alert if not all required fields are filled
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      const response = await axios.post('https://svkrobotics.com/api/orders/newOrder', formData);
      console.log(response.data);
      // Update the state with the orderId received from the server
      setCreatedOrderId(response.data.orderId);
      // Navigate to confirmation page if form submission is successful
      navigate('/confirmation', { state: { orderId: response.data.orderId } });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };



  return (
    <Container className='checkout-cont'>
      <h1 className='checkout-title'>Checkout</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
        </Form.Group>
        <Form.Group controlId="addressLine1">
          <Form.Label>Address Line 1</Form.Label>
          <Form.Control type="text" name="addressLine1" value={formData.addressLine1} onChange={handleChange} required />
        </Form.Group>
        <Form.Group controlId="addressLine2">
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control type="text" name="addressLine2" value={formData.addressLine2} onChange={handleChange} />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" name="city" value={formData.city} onChange={handleChange} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="province">
              <Form.Label>Province</Form.Label>
              <Form.Control type="text" name="province" value={formData.province} onChange={handleChange} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="zip">
              <Form.Label>ZIP Code</Form.Label>
              <Form.Control type="text" name="zip" value={formData.zip} onChange={handleChange} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control as="select" name="country" value={formData.country} onChange={handleChange} required>
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Delivery Options</Form.Label>
          <Row>
            <Col>
              <Form.Check
                type="radio"
                name="deliveryOption"
                id="acs"
                label={
                  <Figure>
                    <Figure.Image width={171} height={180} alt="ACS" src="/logos/acs_logo.png" />
                    <Figure.Caption>Delivery Price: €2.50</Figure.Caption>
                  </Figure>
                }
                value="ACS"
                onChange={handleDeliveryOptionChange}
                required
              />
            </Col>
            <Col>
              <Form.Check
                type="radio"
                name="deliveryOption"
                id="boxNow"
                label={
                  <Figure>
                    <Figure.Image width={171} height={180} alt="Box Now" src="/logos/box_now_logo.png" />
                    <Figure.Caption>Delivery Price: €1.50</Figure.Caption>
                  </Figure>
                }
                value="Box Now"
                onChange={handleDeliveryOptionChange}
                required
              />
            </Col>
          </Row>
        </Form.Group>
        <Button className='order-now-button' size='lg' type="submit" onClick={handleSubmit}>
          Order Now
        </Button>
      </Form>
    </Container>
  );
}

export default Checkout;
