import React from "react";
import { connect } from "react-redux"; // Import connect
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from "react-bootstrap";
import ShoppingCartTable from "../components/ShoppingCartTable";
import { deleteFromCart, updateQuantity } from "../actions/actions";

import "./ShoppingCart.css";

function ShoppingCart(props) { // Receive cartItems from props
    const { cartItems, deleteFromCart, uuid } = props;
    const navigate = useNavigate();

    const proceedToCheckout = () => {
        // Check if cart is not empty
        if (cartItems.length > 0) {
            // Check if UUID is not null or undefined
            if (uuid) {
                // Redirect to checkout
                navigate(`/checkout/${uuid}`);
            } 
            else {
                // UUID is missing, show alert
                alert('UUID is missing. Please add items before proceeding to checkout.');
            }
        } 
        else {
            // Cart is empty, show alert
            alert('Your cart is empty. Please add items before proceeding to checkout.');
        }
    };
    


    return(
        <Container className="shopping-cart-body">
            <Row>
                <Col className="shopping-cart-title">
                    {console.log(uuid)}
                    <h1>My Shopping Cart</h1>
                </Col>
            </Row>
            <Row className="cart-table-row">
                <ShoppingCartTable items={cartItems} deleteFromCart={deleteFromCart} />
            </Row>
            <Button className="checkout-btn" size="lg" onClick={proceedToCheckout}>Proceed to checkout</Button>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    cartItems: state.items.cartItems,
    uuid:  state.items.uuid
});

const mapDispatchToProps = {
    deleteFromCart,
    updateQuantity
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart); // Connect to Redux store and pass deleteFromCart action
