import React, { useState, useEffect } from "react";
import ItemGrid from "../components/ItemGrid";
import { useParams } from "react-router-dom";

import "./ShopGrid.css";

const categoryDisplayNames = {
    "all-items": "All Items",
    "robot-kits": "Robot Kits",
    "robot-parts": "Robot Parts",
    "accessories": "Accessories"
};

const categories = Object.keys(categoryDisplayNames);

function ShopGrid() {
    const { categoryUrl } = useParams();
    const [category, setCategory] = useState("");

    useEffect(() => {
        if (categories.includes(categoryUrl)) {
            setCategory(categoryUrl);
        } else {
            setCategory("all-items");
        }
    }, [categoryUrl]);

    return (
        <>
            <h1 className="grid-title">{categoryDisplayNames[category]}</h1>
            <ItemGrid category={category} />
        </>
    );
}

export default ShopGrid;
