import React, { useState } from "react";
import { Container, Carousel, Modal, Image } from "react-bootstrap";
import { CustomPrevArrow, CustomNextArrow } from "./CustomArrows";

import "./ItemImageCarousel.css";

function ItemImageCarousel({ data }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);

  const handleCarouselSelect = (index) => {
    setSelectedImageIndex(index);
    setSelectedThumbnailIndex(index);
  };

  const handleImageClick = (index) => {
    setShowModal(true);
    setSelectedImageIndex(index);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleThumbnailSelect = (index) => {
    setSelectedImageIndex(index);
    setSelectedThumbnailIndex(index);
  };

  return (
    <>
      <Container className="image-cont">
        {data && data.images && (
          <Carousel
            prevIcon={<CustomPrevArrow />}
            nextIcon={<CustomNextArrow />}
            activeIndex={selectedImageIndex}
            onSelect={handleCarouselSelect}
            interval={null}
            fade
          >
            {data.images.map((imageUrl, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={imageUrl}
                  alt={`Item Image ${index + 1}`}
                  onClick={() => handleImageClick(index)}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </Container>
      {/* Modal for displaying the clicked image */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="xl"
        centered
        dialogClassName="custom-modal"
      >
        {/* Close button in the top right corner */}
        <button className="close-button" onClick={handleCloseModal}>
          <span aria-hidden="true">&times;</span>
        </button>

        <Modal.Body>
          {/* Carousel inside the modal */}
          <Carousel
            prevIcon={<CustomPrevArrow />}
            nextIcon={<CustomNextArrow />}
            interval={null}
            fade
          >
            {data && data.images && data.images.map((imageUrl, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={imageUrl}
                  alt={`Item Image ${index + 1}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
      {/* Array of clickable images */}
      <div className="mt-5 d-flex justify-content-center">
        {data && data.images && data.images.map((image, index) => (
          <Image
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            className={`thumbnail-img mr-3 ${index === selectedThumbnailIndex ? 'selected-thumbnail' : ''}`}
            onClick={() => handleThumbnailSelect(index)}
          />
        ))}
      </div>
    </>
  );
}

export default ItemImageCarousel;
