import React from "react";


// Custom arrow components with SVG
const CustomPrevArrow = (props) => (
    <span
      {...props}
      className="custom-carousel-arrow custom-carousel-prev"
      aria-hidden="true"
    >
      <img src="/icons/arrow-left.svg" alt="Previous" />
    </span>
  );
  
  const CustomNextArrow = (props) => (
    <span
      {...props}
      className="custom-carousel-arrow custom-carousel-next"
      aria-hidden="true"
    >
      <img src="/icons/arrow-right.svg" alt="Next" />
    </span>
  );

  export { CustomPrevArrow, CustomNextArrow };
