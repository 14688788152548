// reducers/index.js
import { combineReducers } from 'redux';
import itemReducer from './itemReducer';

const rootReducer = combineReducers({
  items: itemReducer,
  // Add more reducers for other parts of the state
});

export default rootReducer;
