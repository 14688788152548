import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import './Confirmation.css'; // Import CSS file for styling

const ConfirmationPage = ({ orderId }) => {
  if (!orderId) {
    return <Navigate to="/" />;
  }

  return (
    <Container fluid className="confirmation-container">
      <Row>
        <Col>
          <h1 className="confirmation-heading">Thank you for your order!</h1>
          <p className="confirmation-text">Your order has been successfully placed.</p>
          <h2 className="confirmation-text">Order ID: {orderId}</h2>
          <p className="confirmation-text">SVK Robotics have been informed of the order placed and an email will be sent to you with payment instructions and further details.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default ConfirmationPage;
