import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import Searchbar from './Searchbar';
import ShoppingCartModal from './ShoppingCartModal';
import './Header.css';

const categories = [
  'All Items',
  'Robot Kits',
  'Robot Parts',
  'Accessories'
];


function Header() {

  const [showModal, setShowModal] = useState(false);

  const viewCart = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  return (
    <>
    <Navbar sticky='top' className='header-navbar'>
      <Col>

        <Container className='header-cont'>
          <div className='logo-container'>
            <a href='/'>
              <img src='/logos/logo SVK.png' className='header-logo' alt='Logo' />
            </a>
          </div>
          <div className='searchbar-container'>
            <Searchbar />
          </div>
          {/* <div className='login-col'>
            <img src='/icons/profile-circle.svg' className='login-icon' />
            <Link to={'/'} style={{ textDecoration: "none" , color: "inherit"}}>
              <h5>Login/Register</h5>
            </Link>
          </div> */}
          <div className='shopping-cart-col' onClick={viewCart}>
              <img src='/icons/shopping-cart-01.svg' className='shopping-cart-icon' />
              <h5>View Cart</h5>
          </div>
        </Container>
        
        <Container className='nav-container'>
          <Nav>
          <Col md={2}></Col>
          <Col md={2}>
            <NavDropdown className='nav-dropdown' title="Categories">
            {categories.map((category, index) => {
              // Replace spaces with hyphens
              const categoryUrl = `/category/${category.replace(/\s+/g, '-').toLowerCase()}`;
              return (
                <Link to={categoryUrl} key={index} className="dropdown-item">{category}</Link>
              );
            })}
            </NavDropdown>
            </Col>
            <Col md={1}></Col>

            <div className='nav-links'>
                <Link to="/">Home</Link>
                <Link to="/category/robot-kits">Robots & Kits</Link>
                <Link to="/category/robot-parts">Robot Parts</Link>
              </div>
          </Nav>
        </Container>

      </Col>
    </Navbar>

    <ShoppingCartModal show={showModal} onHide={handleClose} />
    </>
  );
}

export default Header;
